let returnedPayload,
top3Programs,
submitButton,
finalButtonTop,
finalButtonBottom;
let subIdValue, subId2Value, affiliateId, leadIdToken, allFieldValues;

function delay (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const fetchIpAddress = async ipVariable => {
    // Get IP Address from an external link to fetch the global IP
    const getIPAddress = async () => {
        try {
            const response = await fetch(
                'https://europe-north1-heyflow-custom-solutions.cloudfunctions.net/fetchIp',
            );
            const data = await response.json();
            if (data.ip) {
                return data.ip;
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    try {
        const ipInputField = document.querySelector(
            `[data-variable="${ipVariable}"]`,
        );
        if (ipInputField != null) {
            const ipAddress = await getIPAddress();
            ipInputField.value = ipAddress;
        }
    } catch (error) {
        console.error('Error: ', error);
    }
};

const initialSubmitUrl = async payload => {
    await fetch(
        'https://europe-west3-heyflow-clients.cloudfunctions.net/matchTopSchools',
        {
            //await fetch('http://localhost:8080/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        },
    )
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
    })
    .then(data => {
        console.info(data.offers);
        returnedPayload = data.offers;
        filterData(data.offers, payload);
    })
    .catch(error => {
        console.error('Error:', error);
    });
};

const submitHandler = async payload => {
    await fetch(
        'https://europe-west3-heyflow-clients.cloudfunctions.net/submitMatchTopSchools',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        },
    )
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
    })
    .catch(error => {
        console.error('Error:', error);
    });
};

const filterData = (data, fieldValues) => {
    let programsMap = new Map();

    data.forEach(offer => {
        const externalName = offer.details.external_name;
        const formFields = offer.details.form_fields;
        const programData = {};

        offer.details.campuses.forEach(campus => {
            campus.programs.forEach(program => {
                programData["applicantFirstName"] = fieldValues.firstName;
                programData["applicantLastName"] = fieldValues.lastName;
                programData["phone"] = fieldValues.phone;
                programData["affiliateid"] = fieldValues.affiliateid;
                programData["resultId"] = offer.result_id;
                programData["campusId"] = campus.id;
                programData["campusType"] = campus.school_type;
                programData["name"] = offer.details.name;
                programData["programId"] = program.id;
                programData["degree_name"] = program.degree_name;
                programData["payout"] = parseFloat(program.payout);
                programData["logo_url"] = offer.details.logo_url;
                programData["description"] = offer.details.description;

                formFields.forEach(field => {
                    if (field.name !== 'campus_id' || field.name !== 'program_id') {
                        if (field.form_list_items !== null) {
                            if (field.name.includes("international_credits")) {
                                programData[field.post_key] = field.form_list_items[1].value;
                            } else {
                                programData[field.post_key] = field.form_list_items[0].value;
                            }
                        }
                    }
                });

                console.log(programData);
            });
        });

        if (
            !programsMap.has(externalName) ||
            programsMap.get(externalName).payout < programData.payout
        ) {
            programsMap.set(externalName, programData);
        }
    });

    const programs = Array.from(programsMap.values());

    programs.sort((a, b) => b.payout - a.payout);
    const topPrograms = programs.slice(0,
        3);

    const container = document.getElementById('cards-container');
    container.innerHTML = ''; // Clear previous cards

    topPrograms.forEach(program => {
        const card = createCard(program);
        container.appendChild(card);
    });

    console.info(topPrograms);
    top3Programs = topPrograms;
}

const createCard = (program) => {
    const card = document.createElement('div');
    card.className = 'card';
    card.style = `border: 1px solid #ccc; border-radius: 8px; padding: 16px; margin: 16px; width: 300px;`;

    const img = document.createElement('img');
    img.src = program.logo_url;
    img.alt = program.name;
    img.style = `width: 100%; height: auto;`;

    const campusName = document.createElement('h3');
    campusName.innerHTML = program.name;

    const checkboxContainer = document.createElement('div');
    checkboxContainer.id = 'disclaimer-container';

    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.id = 'disclaimer-checkbox';
    checkbox.checked = true;

    const label = document.createElement('label');
    label.htmlFor = 'disclaimer-checkbox';
    label.style = `font-size: .6rem !important;`;

    const disclaimerText = `By submitting this form, I, ${program.applicantFirstName} ${program.applicantLastName}, provide my signature, giving consent to ${program.name} to contact me by telephone (including text, autodialed and prerecorded messages) at the number I have provided and/or contact me via email to receive information about ${program.name}'s offerings. I understand that this consent is not required in order to enroll and I can revoke this consent at any time using any reasonable means. Msg. & data rates`;

    label.textContent = disclaimerText;

    checkboxContainer.appendChild(checkbox);
    checkboxContainer.appendChild(label);

    card.appendChild(img);
    card.appendChild(campusName);
    card.appendChild(checkboxContainer);

    return card;
}

window.addEventListener('heyflow-init', async event => {
    fetchIpAddress('ipAddress');
    const ipAddressInput = document.querySelector('[data-variable="ipAddress"]');
    const ipAddressSection = ipAddressInput.closest('section');
    submitButton = ipAddressSection.querySelector('button.continue');

    const queryString = window.location.search;

    if (queryString != '' || queryString != null) {
        const urlParams = new URLSearchParams(queryString);
        subIdValue = urlParams.get('subid');
        subId2Value = urlParams.get('subid2');
        affiliateId = urlParams.get('affiliateid');
    }
    await delay(3000);
    leadIdToken = window.LeadiD.token;

    submitButton.addEventListener('click', e => {
        initialSubmitUrl(allFieldValues);
    });
});

window.addEventListener('heyflow-change', event => {
    allFieldValues = event.detail.fieldsSimple;
    const ipAddress = document.querySelector(`[data-variable="ipAddress"]`);
    const url = window.location.href;
    const withoutBlobPrefix = url.replace(/^blob:/, '');
    const extractedUrl = withoutBlobPrefix.split('#')[0];
    const baseUrl = url.split('?')[0];

    if ('phone' in allFieldValues) {
        allFieldValues.phone = allFieldValues.phone
        .replace(/^\+1\s*/, '')
        .replace(/\s+/g, '');
        allFieldValues['ipAddress'] = ipAddress.value;
        allFieldValues['consentUrl'] = baseUrl.trim();
        allFieldValues['signupUrl'] = baseUrl.trim();
        allFieldValues['jornayaToken'] = leadIdToken.trim();
        if (subIdValue && affiliateId) {
            allFieldValues['subid'] = subIdValue;
            allFieldValues['affiliateid'] = affiliateId;
            if (subId2Value) {
                allFieldValues['subid2'] = subId2Value;
            }
        }
    }
});

window.addEventListener('heyflow-submit', event => {
    if (top3Programs.length !== 0) {
        submitHandler(top3Programs);
    }
});